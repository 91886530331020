<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h1>Aged Debt</h1>
      </v-col>
    </v-row>
    <v-form @submit.prevent="searchInvoices">
      <v-row class="pt-8">
        <v-col>
          <label>Customer</label>
          <v-text-field v-model="customer"></v-text-field>
        </v-col>
        <v-col>
          <label>Sage Ref</label>
          <v-text-field v-model="sageRef"></v-text-field>
        </v-col>
        <v-col class="mt-10">
          <v-btn class="mb-2" small type="sumbit">Search</v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-row v-if="page < length">
      <v-col class="text-right pb-10">
        <v-btn @click="page += 1" large>Load More</v-btn>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col class="text-right pb-10">
        <download-csv
          name="aged-debt.csv"
          :data="invoices"
          :fields="exportFields">
          <v-btn>Export</v-btn>
        </download-csv>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table>
          <thead>
            <tr>
              <th style="width:40%"></th>
              <th style="width:10%">Total Value</th>
              <th style="width:10%">Total Credits</th>
              <th style="width:10%">Total Payments</th>
              <th style="width:10%">Total Retentions</th>
              <th style="width:20%" class="text-right">Total Outstanding</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>&nbsp;</td>
              <td style="width:10%">{{ totalValue | priceInPounds | currency }}</td>
              <td style="width:10%">{{ totalCredit | priceInPounds | currency }}</td>
              <td style="width:10%">{{ totalPayment | priceInPounds | currency }}</td>
              <td style="width:10%">{{ totalRetention | priceInPounds | currency }}</td>
              <td style="width:20%" class="text-right">{{ totalOutstanding | priceInPounds | currency }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pb-5">
        <v-simple-table fixed-header height="500">
          <thead>
            <tr>
              <th style="width:10%">Invoice Number</th>
              <th style="width:10%">Date</th>
              <th style="width:10%">Sage Ref</th>
              <th style="width:10%">Customer</th>
              <th style="width:10%">Total Value</th>
              <th style="width:10%">Credits</th>
              <th style="width:10%">Payments</th>
              <th style="width:10%">Retentions</th>
              <th style="width:20%" class="text-right">Outstanding</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(invoice, index) in invoices" :key="`dl_${index}`">
              <td>{{ invoice.number }}</td>
              <td>{{ invoice.date | tinyDate }}</td>
              <td>{{ invoice.sageRef }}</td>
              <td>{{ invoice.customer }}</td>
              <td>&pound;{{ invoice.amount }}</td>
              <td>&pound;{{ invoice.amountCredited }}</td>
              <td>&pound;{{ invoice.amountPaid }}</td>
              <td>&pound;{{ invoice.amountRetained }}</td>
              <td class="text-right">&pound;{{ invoice.amountDue }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row v-if="page < length">
      <v-col class="text-right pb-10">
        <v-btn @click="page += 1" large>Load More</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'AgedDebt',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      invoices: [],
      page: 1,
      length: 0,
      totalValue: 0,
      totalCredit: 0,
      totalPayment: 0,
      totalRetention: 0,
      totalOutstanding: 0,
      exportFields: [
        'customer',
        'sageRef',
        'number',
        'date',
        'amount',
        'amountPaid',
        'amountCredited',
        'amountRetained',
        'amountDue',
      ],
      labels: [
        'Customer',
        'Sage Ref',
        'Number',
        'Date',
        'Amount',
        'Amount Paid',
        'Amount Credited',
        'Amount Retained',
        'Amount Due',
      ],
    };
  },
  watch: {
    page() {
      this.getInvoices();
    },
  },
  methods: {
    searchInvoices() {
      this.page = 1;
      this.invoices = [];
      this.totalValue = 0;
      this.totalCredit = 0;
      this.totalPayment = 0;
      this.totalRetention = 0;
      this.totalOutstanding = 0;
      this.getInvoices();
    },
    getInvoices() {
      const postData = {
        customer: this.customer,
        sageRef: this.sageRef,
      };
      axios.post(`/invoices/getAgedDebt/${this.page}.json?token=${this.token}`, postData)
        .then((response) => {
          this.invoices = this.invoices.concat(response.data.invoices);
          this.length = response.data.length;
          this.totalValue += response.data.totals.value;
          this.totalCredit += response.data.totals.credit;
          this.totalPayment += response.data.totals.payment;
          this.totalRetention += response.data.totals.retention;
          this.totalOutstanding += response.data.totals.outstanding;
        });
    },
  },
  mounted() {
    this.getInvoices();
  },
};
</script>
  